const FIELD_NAMES = {
    "Injury": "Przebyte urazy/operacje",
    "Disease": "Choroby towarzyszące",
    "EmotionalAspect": "Aspekt emocjonalny",
    "Treatment": "Dotychczasowe leczenie",
    "Pregnancy": "Ciąża w trakcie",
    "BirthDate": "Planowana data porodu",
    "PregnancyEnded": "Ciąża zakończona",
    "ChildrenList": "Lista ciąż",
    "ICD9": "Kody ICD-9",
    "ICD10": "Kody ICD-10",
    "ICF": "Kody ICF",
    "InterviewField": "Wywiad",
    "FunctionalTest": "Badania funkcjonalne",
    "PhysicalActivity": "Aktywność fizyczna",
    "LastMenstrualPeriod": "Ostatnia miesiączka",
    "PeriodPains": "Bóle miesiączkowe i bolesność piersi",
    "ContraceptionSexualActivity": "Antykoncepcja oraz aktywność seksualna",
    "MicturitionDefecationn": "Mikcja oraz defekacja",
    "LastGynecologistVisit": "Ostatnia wizyta u ginekologa",
    "LastUroPhysiotherapistVisit": "Ostatnia wizyta u fizjoterapeuty urologicznego",
    "StomachMusclesVisualization": "Mięśnie brzucha",
    "DRA": "DRA - Ocena rozejścia mięśni prostych brzucha",
    "ScarEvaluationVisualization": "Ocena blizn",
    "GraphicalCrotchVisualization": "Graficzny obraz krocza",
    "CrotchVisualization": "Krocze",
    "Notes": "Notatki",
    "PerfectOxfordScale": "Skala Perfect i Oxford - Ocena dna miednicy",
    "POPQScale": "Ocena obniżenia narządów miednicy mniejszej - Skala POP - Q",
    "Therapy": "Przeprowadzona terapia",
    "Teaching": "Instruktaż",
    "Recommendation": "Zalecenia",
    "IssuedDocs": "Wydane opinie/orzeczenia/zaświadczenia",
    "Medicaments": "Zlecone wyroby medyczne"
}

export default FIELD_NAMES;