const FIELD_NAMES = {
    "Injury": "Przebyte urazy/operacje",
    "Disease": "Choroby towarzyszące",
    "EmotionalAspect": "Aspekt emocjonalny",
    "Treatment": "Dotychczasowe leczenie",
    "ICD9": "Kody ICD-9",
    "ICD10": "Kody ICD-10",
    "ICF": "Kody ICF",
    "InterviewField": "Wywiad",
    "FunctionalTest": "Badania funkcjonalne",
    "Other": "Inne",
    "Drawings": "Ilustracja dolegliwości",
    "Plan": "Plan fizjoterapii",
    "TherapyField": "Terapia",
    "Recommendation": "Zalecenia",
    "IssuedDocs": "Wydane opinie/orzeczenia/zaświadczenia",
    "Medicaments": "Zlecone wyroby medyczne"
}

export default FIELD_NAMES;